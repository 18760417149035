.toggle-edit-btn {
    color: #c10e21;
    display: flex;
    align-items: center;
    position: relative;
    .toggle-edit-checkbox {
        width: 0;
        height: 0;
        opacity: 0;
        &:focus {
            & + .toggle-label {
                outline: rgb(247, 152, 58) solid 2px;
                box-shadow: rgb(247, 152, 58) 0px 0px 4px 4px;
            }
        }
    }
    .toggle-label {
        margin: 0;
        cursor: pointer;
        white-space: nowrap;
    }
    .toggle-edit {
        font-size: 24px;
    }
    .toggle-canceledit {
        font-size: 16px;
        position: absolute;
        right: 0;
    }
    .toggle-label-edit {
        font-size: 0.1px;
        width: 35px;
    }
}
.toggle-label-word {
    .toggle-label {
        font-size: 16px;
    }
    .toggle-canceledit {
        position: static;
    }
}
.view-toggle-switch {
    font-size: 0.1px;
    position: relative;
    display: inline-block;
}
.view-toggle-slider {
    display: flex;
    width: 110px;
    height: 32px;
    background-color: #ffffff;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    border: 1px solid #cccccc;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    &:before {
        position: absolute;
        content: "";
        height: 28px;
        width: 55px;
        left: 1px;
        bottom: 1px;
        background-color: white;
        -webkit-transition: 0.2s;
        transition: 0.2s;
        background-color: #c10e21;
        border-radius: 20px;
    }
    .toggle-graph-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        height: 28px;
        width: 55px;
        z-index: 5;
        font-size: 14px;
    }
}
.view-toggle-control {
    position: absolute;
    width: 110px;
    height: 32px;
    z-index: 9;
}
.view-toggle-input {
    opacity: 0;
    width: 100%;
    height: 32px;
    position: absolute;
    left: 0;
    &:checked {
        ~ .view-toggle-slider {
            &:before {
                -webkit-transform: translateX(51px);
                -ms-transform: translateX(51px);
                transform: translateX(51px);
            }
            .toggle-graph-icon1 {
                color: #777979;
            }
            .toggle-graph-icon2 {
                color: #ffffff;
            }
        }
    }
    &:not(:checked) {
        ~ .view-toggle-slider {
            .toggle-graph-icon2 {
                color: #777979;
            }
            .toggle-graph-icon1 {
                color: #ffffff;
            }
        }
    }
    &:focus {
        & + .view-toggle-slider {
            outline: 2px solid #f7983a;
            box-shadow: 0 0 4px 4px #f7983a;
        }
    }
    &:checked ~ .view-toggle-label {
        &:before {
            content: 'Table View';
            position: absolute;
            width: 1px;
            height: 1px;
            overflow: hidden;
        }
    }
    &:not(:checked) ~ .view-toggle-label {
        &:before {
            content: 'Chart View';
            position: absolute;
            width: 1px;
            height: 1px;
            overflow: hidden;
        }
    }
    &:focus + label.view-toggle-control {
        outline: 2px solid #d6700a !important;
        -webkit-box-shadow: 0 0 4px 4px #d6700a !important;
        box-shadow: 0 0 4px 4px #d6700a !important;
    }
}
.edit-btn-toggle {
    color: #c10e21;
    display: inline-block;
    position: relative;
    cursor: pointer;
    .edit-btn-checkbox {
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        left: 0;
        z-index: -1;
        cursor: pointer;
        &:focus {
            & + .toggle-label {
                outline: rgb(247, 152, 58) solid 2px;
                box-shadow: rgb(247, 152, 58) 0px 0px 4px 4px;
            }
        }
    }
    .toggle-label {
        cursor: pointer;
        margin: 0;
    }
    .toggle-edit {
        font-size: 24px;
    }
}
